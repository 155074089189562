<template>
  <div id="app">
    <!-- Header导航栏部分 -->
    <header class="header">
      <HeaderBar />
    </header>
    <!-- Banner部分 -->
    <section class="banner-container dialog-parent">
      <img class="rectangle" src="@/assets/images/index/Rectangle.png" />
      <div class="banner-text-container">
        <div class="banner-title">{{ $t("welcomeMainMsg") }}</div>
        <div class="banner-description-container">
          <div class="banner-description" style="margin-top: 20px">
            {{ $t("welcomeSubMsg") }}
          </div>
        </div>
        <div class="submitbtn-container">
          <el-button class="submit-btn" @click="navToSubmit">{{
            $t("submitIdea")
          }}</el-button>
        </div>
      </div>
    </section>

    <!-- 卡片部分 -->
    <section class="nav-container">
      <div class="nav-text-firstline">{{ $t("andThen") }}</div>
      <div class="nav-text-secondline">
        {{ $t("andThenMsg") }}
      </div>
      <el-row class="card-group-container">
        <el-col
          :xs="{ span: 10 }"
          v-for="cardInfo in statusOptions"
          :key="cardInfo.StatusCode"
        >
          <div class="card-wrapper" @click="navToBrowse(cardInfo.StatusCode)">
            <el-card
              class="card-container"
              :style="{
                backgroundImage: backgroundImage(cardInfo.StatusCode).value,
              }"
            >
              <!-- Card content goes here -->
              <h1 class="card-title">
                {{ getStatusText(cardInfo.StatusCode) }}
              </h1>
              <div class="cardinfo-count-container">
                {{ cardInfo.StatusCount }}
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </section>

    <!-- 灰色基底部分 -->
    <section class="grey-container">
      <div class="grey-left-container">
        <el-menu
          default-active="1"
          mode="horizontal"
          :ellipsis="false"
          class="custom-menu"
          @select="handleIdeaStatusSelect"
        >
          <el-menu-item index="1">{{ $t("new") }}</el-menu-item>
          <el-menu-item index="2">{{ $t("discussion") }}</el-menu-item>
          <el-menu-item index="3">{{ $t("building") }}</el-menu-item>
          <el-menu-item index="4">{{ $t("pilot") }}</el-menu-item>
        </el-menu>
        <div class="newscard-group-container">
          <div
            v-for="(Idea, index) in filteredIdeaList.slice(0, IdeaPageSize)"
            :key="index"
          >
            <NewsCard :ideaItem="Idea" />
          </div>
        </div>

        <el-button
          round
          plain="true"
          class="viewMoreBtnRow"
          style="margin-left: 2rem"
          @click="viewMoreIdea"
          v-if="
            currentIdeaStatus === 1
              ? newIdeaList.length > IdeaPageSize
              : currentIdeaStatus === 2
              ? workInProgressIdeaList.length > IdeaPageSize
              : playgroundIdeaList.length > IdeaPageSize
          "
          >{{ $t("viewMore") }}</el-button
        >
      </div>
      <div class="grey-right-container">
        <div class="action-bar">
          <span class="action-bar-text">{{ $t("updates") }}</span>
          <el-badge
            :value="updateList.length"
            style="margin-left: 0.75rem"
          ></el-badge>
        </div>
        <div
          v-for="(updateItem, index) in updateList.slice(0, UpdatePageSize)"
          :key="index"
        >
          <UpdatesCard :updatesItems="updateItem"></UpdatesCard>
        </div>
        <el-button
          round
          plain="true"
          class="viewMoreBtnRow"
          @click="viewMoreUpdate"
          v-if="updateList.length > UpdatePageSize"
          >{{ $t("viewMore") }}</el-button
        >
      </div>
    </section>

    <img src="@/assets/images/index/Rectangle_bottom.png" style="width: 100%" />
    <!-- Footer部分 -->
    <section class="footer">
      <div>
        <div class="footer-firstline">{{ $t("aboutGSK") }}</div>
        <div class="footer-lastline">{{ $t("aboutGSKMsg") }}</div>
      </div>
    </section>

    <section class="footer_bar">
      <FooterBar :activeItem="1"></FooterBar>
    </section>
  </div>
</template>

<style scoped>
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.banner-container {
  position: relative;
  background-size: cover;
  background-position: center;
  background-size: cover;
  background-position: center;
  background-image: url("@/assets/images/login/TOP.png");
  width: 100%;
  height: 40rem;
  display: flex;
  justify-content: center;
}

.submit-btn {
  color: #fff;
  margin-top: 50px;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  height: 56px;
  padding: 0px 32px;
  border-radius: 10px;
  --el-button-bg-color: #f36633 !important;
  --el-button-border-color: #f36633 !important;
  --el-button-hover-bg-color: #376f7b !important;
  --el-button-hover-text-color: white !important;
  --el-button-hover-border-color: #376f7b !important;
  --el-button-active-bg-color: #376f7b !important;
  --el-button-active-border-color: #376f7b !important;
}

.rectangle {
  position: absolute;
  bottom: 0;
  z-index: 1000;
  width: 100%;
}

.banner-text-container {
  margin: auto;
  z-index: 999;
}

.banner-title {
  color: #3c3c43;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.banner-description-container {
  width: 60rem;
}

.banner-description {
  color: #484851;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.submitbtn-container {
  display: flex;
  justify-content: center;
}

.nav-container {
  width: 100%;
  min-height: 60vh;
  text-align: center;
}

.nav-text-firstline {
  color: #484851;
  margin-top: 2rem;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.75rem;
}

.nav-text-secondline {
  margin-top: 0.5rem;
  color: #484851;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.25rem;
}

.card-group-container {
  margin-top: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  gap: 2rem;
}

.card-container {
  display: flex;
  align-items: flex-start;
  /* 垂直方向顶部对齐 */
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  aspect-ratio: 3 / 4;
}

.card-wrapper {
  margin: 1vw;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.card-title {
  font-size: 3vh;
  font-style: normal;
  font-weight: 700;
}

.cardinfo-count-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 5rem;
  width: 4.5rem;
  height: 4.5rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  color: #3c3c43;
}

.grey-container {
  width: 100%;
  min-height: 70rem;
  background-color: #f6f6f6;
  box-sizing: border-box;
  padding: 5rem 7.5rem 9.75rem 7.5rem;
  z-index: -1;
  gap: 3vw;
  display: flex;
}

.grey-left-container {
  flex: 2.4;
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;
  background-color: #fff;
}

.grey-right-container {
  flex: 1;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 24px;
  padding-bottom: 24px;
}

.action-bar {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.action-bar-text {
  color: var(--, #3c3c43);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.custom-menu {
  padding-left: 35px;
  background-color: transparent;
  margin-top: 5px;
}

.custom-menu .el-menu-item {
  color: #484851 !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.el-menu {
  --el-menu-border-color: #f6f6f6;
}

.custom-menu .el-menu-item:hover,
.custom-menu .el-menu-item:focus,
.custom-menu .el-menu-item:active {
  background-color: transparent !important;
  /* Remove hover and click background color */
  color: #f36633 !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #f36633 !important;
  border-color: #f36633 !important;
}

.custom-menu .el-menu-item.is-active {
  color: inherit;
  /* Remove active color */
}

.big-badge .el-badge__content {
  display: flex;
  height: 24px;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 26px;
  background: #f36633;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
}

.newscard-group-container {
  width: 100%;
}

.viewMoreBtnRow {
  margin-top: 24px;
  width: 8rem;
  padding-right: 1rem;
  padding-left: 1rem;
  color: #484851;
  font-family: GSK Precision;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
  --el-button-hover-text-color: #484851 !important;
  --el-button-hover-border-color: #c9c9cf !important;
  --el-color-primary: #f36633;
}

.footer {
  text-align: center;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.footer-firstline {
  color: #3c3c43;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.footer-lastline {
  margin-top: 0.5rem;
  color: #484851;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
}

.card-wrapper .card-container:hover {
  background-image: url("@/assets/images/index/HOVER.png") !important;
  color: white !important;
}

.footer_bar {
  display: none;
}
@media only screen and (max-width: 600px) {
  .footer_bar {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 9999999;
  }
  .banner-title {
    font-size: 32px;
  }
  .banner-description {
    font-size: 18px;
  }
  .grey-container {
    padding: 1rem;
    display: block;
  }
  .banner-description-container {
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto;
  }
  .custom-menu {
    padding-left: 0.5rem;
  }
  .custom-menu .el-menu-item {
    width: 100%;
    font-size: 16px;
  }
  .grey-left-container {
    padding-top: 0;
  }
  .el-menu-item {
    padding: 0 10px;
  }
  .nav-container {
    min-height: auto;
  }
  .card-title {
    font-size: 1.5rem;
  }
  .cardinfo-count-container {
    margin-top: 3rem;
    font-size: 1.5rem;
  }
  .footer {
    padding-bottom: 10rem;
    height: auto;
  }
  .rectangle {
    height: 2rem;
  }
}
</style>

<script lang="ts">
import HeaderBar from "@/components/HeaderBar.vue";
import NewsCard from "@/components/NewsCard.vue";
import UpdatesCard from "@/components/UpdatesCard.vue";
import { useRouter } from "vue-router";
import { reactive, inject, onMounted, computed, ComputedRef, ref } from "vue";
import { AxiosInstance } from "axios";
import { getUserInfo } from "@/state/UserInfo";
import { getStatusText } from "@/utils/getStatusText";
import FooterBar from "@/components/FooterBar.vue";

export default {
  components: {
    HeaderBar,
    NewsCard,
    UpdatesCard,
    FooterBar,
  },
  setup() {
    interface StatusOption {
      StatusCode: number;
      StatusCount: number;
      StatusName: string;
    }
    interface IdeaItem {
      Idea: Idea;
      IsCollect: boolean;
      IsLike: boolean;
      User: User;
    }

    interface Idea {
      CollectCount: number;
      CommentCount: number;
      CreatedBy: number;
      CreatedOn: string;
      Description: string;
      IdeaId: number;
      LikeCount: number;
      ModifiedBy: number;
      ModifiedOn: string;
      Owner: number;
      PlaygroundCreatedBy: number;
      PlaygroundCreatedOn: string;
      PlaygroundModifiedBy: number;
      PlaygroundModifiedOn: string;
      State: number;
      Status: number;
      SubmittedOn: string;
      Title: string;
      BannerImgUrl: string;
    }

    interface User {
      Avatar: string;
      UserId: number;
      UserName: string;
    }

    interface UpdateItem {
      User: User;
      Update: Update;
    }

    interface Update {
      Content: string;
      CreatedBy: number;
      CreatedOn: string;
      IdeaId: number;
      UpdateId: number;
      UserId: number;
      IdeaTitle: string;
      Type: number;
    }
    const axiosInstance = inject("axiosInstance") as AxiosInstance;
    onMounted(async () => {
      console.log(router.currentRoute.value.params);
      await getIdeaStatusCount();
      await getIdeaList();
      getMyUpdateList();
    });
    let ideaList = reactive<IdeaItem[]>([]);
    let newIdeaList = reactive<IdeaItem[]>([]);
    let workInProgressIdeaList = reactive<IdeaItem[]>([]);
    let playgroundIdeaList = reactive<IdeaItem[]>([]);
    const IdeaPageSize = ref(4);
    const UpdatePageSize = ref(4);

    const updateList = ref<UpdateItem[]>([]);
    /**
     * Retrieves the list of ideas from the server and rearranges them into columns.
     * The resulting list is assigned to both the `ideaList` and `checkedIdeaList` variables.
     * @function
     * @name getIdeaList
     * @returns {void}
     */
    const getIdeaList = () => {
      return axiosInstance
        .request({
          url: "api/Idea/GetIdeaList",
          data: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
            PageSize: 2147483647,
          },
          method: "post",
        })
        .then((res) => {
          Object.assign(ideaList, res.data.Data);
          Object.assign(
            newIdeaList,
            ideaList.filter((item) => item.Idea.Status === 1)
          );
          Object.assign(
            workInProgressIdeaList,
            ideaList.filter(
              (item) => item.Idea.Status === 2 || item.Idea.Status === 3
            )
          );
          Object.assign(
            playgroundIdeaList,
            ideaList.filter((item) => item.Idea.Status === 4)
          );
        });
    };
    const router = useRouter();
    const navToSubmit = () => {
      router.push("/submit");
    };
    let statusOptions = reactive<StatusOption[]>([]);
    /**
     * Retrieves the count of ideas for each status from the server and assigns the resulting list to the `statusOptions` variable.
     * Each option in the list is also assigned a `isChecked` property with a value of `true`.
     * @function
     * @name getIdeaStatusCount
     * @returns {void}
     */
    const getIdeaStatusCount = () => {
      axiosInstance
        .request({
          url: "api/Idea/GetIdeaStatusCount",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "get",
        })
        .then((res) => {
          Object.assign(statusOptions, res.data.Data);
        });
    };
    const backgroundImage = (StatusCode: number): ComputedRef<string> =>
      computed(() => {
        const statusText = getStatusText(StatusCode);
        const image = require(`@/assets/images/index/${statusText}.png`);
        console.log(image);
        return `url(${image})`;
      });

    const navToBrowse = (StatusCode: number) => {
      router.push({
        name: "browse",
        query: {
          StatusCode: StatusCode,
        },
      });
    };
    const currentIdeaStatus = ref(1);
    const handleIdeaStatusSelect = (index: string) => {
      IdeaPageSize.value = 4;
      currentIdeaStatus.value = Number(index);
    };

    const getMyUpdateList = () => {
      axiosInstance
        .request({
          url: "api/Update/getMyUpdateList",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "get",
        })
        .then((res) => {
          updateList.value = res.data.Data;
          console.log("updateList", updateList.value);
        });
    };

    const viewMoreIdea = () => {
      IdeaPageSize.value = IdeaPageSize.value + 4;
    };

    const viewMoreUpdate = () => {
      UpdatePageSize.value = UpdatePageSize.value + 4;
    };

    const filteredIdeaList = computed(() => {
      return ideaList.filter(
        (idea) => idea.Idea.Status == currentIdeaStatus.value
      );
    });
    return {
      updateList,
      statusOptions,
      currentIdeaStatus,
      newIdeaList,
      workInProgressIdeaList,
      playgroundIdeaList,
      IdeaPageSize,
      UpdatePageSize,
      ideaList,
      viewMoreIdea,
      viewMoreUpdate,
      navToBrowse,
      backgroundImage,
      getStatusText,
      navToSubmit,
      handleIdeaStatusSelect,
      filteredIdeaList,
    };
  },
};
</script>
