<template>
  <el-collapse v-model="activeNames" style="width: 100%">
    <!-- Status Filter -->
    <el-collapse-item :title="$t('status')" name="Status">
      <el-checkbox-group
        class="custom-checkbox-group"
        v-model="checkedStatus"
        @change="handleStatusChange"
      >
        <el-checkbox
          v-for="(statusOption, statusoption_index) in statusOptions"
          :key="statusoption_index"
          :label="statusOption.StatusCode"
          v-model="statusOption.isChecked"
          :checked="statusOption.isChecked"
        >
          <span>{{ statusOption.StatusName }}</span>
          <div
            class="checkbox-right"
            :style="{
              color: statusOption.isChecked
                ? getStatusColor(statusOption.StatusCode)
                : '#868693',
            }"
          >
            {{ statusOption.StatusCount }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              viewBox="0 0 8 9"
              fill="none"
            >
              <circle
                cx="4"
                cy="4.5"
                r="4"
                :fill="getStatusColor(statusOption.StatusCode)"
              />
            </svg>
          </div>
        </el-checkbox>
      </el-checkbox-group>
    </el-collapse-item>
    <!-- Create Time Filter -->
    <div class="time-filter">
      <span class="time-filter-text">{{ $t("createTime") }}</span>
      <span class="time-filter-clear" @click="handleClearCreateTime">{{
        $t("clear")
      }}</span>
    </div>
    <el-date-picker
      v-model="createTime"
      type="daterange"
      unlink-panels
      range-separator="-"
      :shortcuts="shortcuts"
      start-placeholder="Start Time"
      end-placeholder="End Time"
      style="width: 100%; margin-bottom: 1.5rem"
      @change="handleCreateTimeChange"
    />
    <!-- Last Update Filter -->
    <div class="time-filter">
      <span class="time-filter-text">{{ $t("lastUpdate") }}</span>
      <span class="time-filter-clear" @click="handleClearUpdateTime">{{
        $t("clear")
      }}</span>
    </div>
    <el-date-picker
      v-model="lastUpdateTime"
      type="daterange"
      unlink-panels
      range-separator="-"
      :shortcuts="shortcuts"
      start-placeholder="Start Time"
      end-placeholder="End Time"
      style="width: 100%; margin-bottom: 1.5rem"
      @change="handleLastUpdateTimeChange"
    />
    <!-- Owner Filter -->
    <el-collapse-item :title="$t('owner')" name="Owner">
      <el-checkbox-group
        class="custom-checkbox-group"
        v-model="checkedOwner"
        @change="handleOwnerChange"
      >
        <el-checkbox
          v-for="ownerOption in ownerOptions"
          :key="ownerOption.UserId"
          :label="ownerOption.UserId"
          v-model="ownerOption.isChecked"
          :checked="ownerOption.isChecked"
        >
          <span>{{ ownerOption.UserName }}</span>
        </el-checkbox>
      </el-checkbox-group>
    </el-collapse-item>
  </el-collapse>
</template>

<style scoped>
.custom-checkbox .content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.collapseItem-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.icon {
  width: 16px;
  height: 16px;
}

:deep(.el-collapse-item__wrap) {
  border-bottom: none;
}

:deep(.el-collapse-item__header) {
  border-bottom: none;
}

.custom-checkbox-group {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}
.el-checkbox {
  display: flex;
  width: 90%;
  align-items: center;
  --el-checkbox-checked-text-color: #484851;
}

:deep(.el-checkbox__label) {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  flex-grow: 1;
  color: #484851;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
}

.checkbox-right {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #868693;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

:deep(.el-collapse-item__arrow) {
  margin-left: 12px;
}

:deep(.el-collapse-item__header.is-active .el-collapse-item__arrow) {
  transform: rotate(180deg);
}

:deep(.el-collapse-item__header) {
  color: #3c3c43;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
}

:deep(.el-collapse-item__arrow) {
  /* 隐藏默认箭头 */
  visibility: hidden;
  position: relative;
}
:deep(.el-collapse-item__arrow::after) {
  /* 显示自定义图标 */
  content: url("@/assets/icons/caret-down.svg"); /* 替换为你的图标的路径 */
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
}

.time-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.time-filter-text {
  color: #3c3c43;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.time-filter-clear {
  color: #868693;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}
:deep(.el-date-editor .el-range-input) {
  color: #3c3c43;
  font-family: GSK Precision;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
</style>

<script lang="ts">
import { ref, reactive, onMounted, inject, defineComponent } from "vue";
import { AxiosInstance } from "axios";
import { getUserInfo } from "@/state/UserInfo";
import { getStatusColor } from "@/utils/getStatusColor";

export default defineComponent({
  name: "ManagementIdeaFilter",
  setup(props, context) {
    const activeNames = ref(["Status", "Owner"]);

    interface StatusOption {
      StatusCode: number;
      StatusCount: number;
      StatusName: string;
      isChecked: boolean;
    }

    interface OwnerOption {
      UserId: number;
      UserName: string;
      Avatar: string;
      isChecked: boolean;
    }

    let statusOptions = reactive<StatusOption[]>([]);
    let ownerOptions = reactive<OwnerOption[]>([]);
    const shortcuts = [
      {
        text: "Last week",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
      {
        text: "Last month",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
      {
        text: "Last 3 months",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        },
      },
    ];
    const createTime = ref("");
    const lastUpdateTime = ref("");
    const checkedStatus = ref<number[]>([]);
    const checkedOwner = ref<number[]>([]);
    const axiosInstance = inject("axiosInstance") as AxiosInstance;

    onMounted(() => {
      getIdeaStatusCount();
      getIdeaOwnerList();
    });

    /**
     * Retrieves the count of ideas for each status from the server and assigns the resulting list to the `statusOptions` variable.
     * Each option in the list is also assigned a `isChecked` property with a value of `true`.
     * @function
     * @name getIdeaStatusCount
     * @returns {void}
     */
    const getIdeaStatusCount = () => {
      axiosInstance
        .request({
          url: "api/Idea/GetIdeaStatusCount",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "get",
        })
        .then((res) => {
          const StatusOptions = res.data.Data;
          StatusOptions.forEach((option: StatusOption) => {
            option.isChecked = true;
          });
          Object.assign(statusOptions, StatusOptions);
        });
    };

    /**
     * Retrieves the list of idea owners from the server and assigns the resulting list to the `ownerOptions` variable.
     * Each option in the list is also assigned a `isChecked` property with a value of `true`.
     * @function
     * @name getIdeaOwnerList
     * @returns {void}
     */
    const getIdeaOwnerList = () => {
      axiosInstance
        .request({
          url: "api/Idea/GetIdeaOwnerList",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "get",
        })
        .then((res) => {
          const OwnerOptions = res.data.Data;
          OwnerOptions.forEach((option: OwnerOption) => {
            option.isChecked = false;
          });
          Object.assign(ownerOptions, OwnerOptions);
        });
    };

    /**
     * Handles the change event for the status filter checkboxes.
     * Loops through each option in the `statusOptions` array and sets its `isChecked` property to `true` if its `StatusName` property is included in the `checkedStatus` array.
     * Calls the `checkedIdeaListChange` function to update the list of checked ideas.
     * @function
     * @name handleStatusChange
     * @returns {void}
     */
    const handleStatusChange = () => {
      statusOptions.forEach((option) => {
        option.isChecked = checkedStatus.value.includes(option.StatusCode);
      });
      emitValues();
    };

    /**
     * Handles the change event for the owner filter checkboxes.
     * Loops through each option in the `ownerOptions` array and sets its `isChecked` property to `true` if its `UserName` property is included in the `checkedOwner` array.
     * Calls the `checkedIdeaListChange` function to update the list of checked ideas.
     * @function
     * @name handleOwnerChange
     * @returns {void}
     */
    const handleOwnerChange = () => {
      ownerOptions.forEach((option) => {
        option.isChecked = checkedOwner.value.includes(option.UserId);
      });
      emitValues();
    };

    const emitValues = () => {
      context.emit("filterUpdate", {
        checkedStatus: checkedStatus.value,
        checkedOwner: checkedOwner.value,
      });
    };

    const handleClearCreateTime = () => {
      createTime.value = "";
      context.emit("filterUpdate", {
        createTime: createTime.value,
      });
    };
    const handleClearUpdateTime = () => {
      lastUpdateTime.value = "";
      context.emit("filterUpdate", {
        lastUpdateTime: lastUpdateTime.value,
      });
    };

    const handleCreateTimeChange = () => {
      context.emit("filterUpdate", {
        createTime: createTime.value,
      });
    };

    const handleLastUpdateTimeChange = () => {
      context.emit("filterUpdate", {
        lastUpdateTime: lastUpdateTime.value,
      });
    };
    return {
      activeNames,
      statusOptions,
      checkedStatus,
      ownerOptions,
      checkedOwner,
      shortcuts,
      createTime,
      lastUpdateTime,
      handleClearCreateTime,
      handleClearUpdateTime,
      handleCreateTimeChange,
      handleLastUpdateTimeChange,
      handleStatusChange,
      handleOwnerChange,
      getStatusColor,
    };
  },
});
</script>
