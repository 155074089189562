<template>
  <div id="app">
    <!-- Header 导航栏部分 -->
    <header class="header">
      <HeaderBar :index="'/browse'" />
    </header>

    <!-- Banner 部分 -->
    <section
      class="banner-container"
      :style="{
        backgroundImage:
          'url(' +
          IdeaItem.Idea.BannerImgUrl +
          '?' +
          getSasTokenFromCache() +
          ')',
      }"
    >
      <div class="rectangle">
        <div class="creator-info">
          <div class="creator-name">{{ IdeaItem.User.UserName }}</div>
          <div class="avatar-container">
            <el-avatar
              :src="'data:image/jpeg;base64,' + IdeaItem.User.Avatar"
            ></el-avatar>
          </div>
        </div>
      </div>
    </section>

    <!-- Title Bar 部分 -->
    <section class="ideaheader-container">
      <div class="titlebar-container">
        <el-page-header
          @back="goBack"
          icon="ArrowLeft"
          :title="IdeaItem.Idea.Title"
        >
        </el-page-header>
        <div class="tool-container">
          <!-- 收藏图标 -->
          <button
            @click="handleCollectClick(IdeaItem.Idea.IdeaId)"
            class="icon-button"
          >
            <img src="@/assets/icons/star.svg" v-show="!IdeaItem.IsCollect" />
            <img
              src="@/assets/icons/star-fill.svg"
              v-show="IdeaItem.IsCollect"
            />
            <span class="icon-text">{{
              IdeaItem.Idea.CollectCount
            }}</span></button
          ><!-- 点赞图标 -->
          <button
            @click="handleLikeClick(IdeaItem.Idea.IdeaId)"
            class="icon-button"
          >
            <img src="@/assets/icons/zan.svg" v-show="!IdeaItem.IsLike" />
            <img src="@/assets/icons/zan-fill.svg" v-show="IdeaItem.IsLike" />
            <span class="icon-text">{{ IdeaItem.Idea.LikeCount }}</span>
          </button>
          <!-- 评论图标 -->
          <button class="icon-button" style="cursor: auto">
            <img src="@/assets/icons/comment.svg" />
            <span class="icon-text">{{ IdeaItem.Idea.CommentCount }}</span>
          </button>
          <!-- 关注图标 -->
          <el-popover
            placement="bottom-start"
            trigger="hover"
            :width="200"
            :content="$t('followIdeaMsg')"
            v-if="!IdeaItem.IsFollow"
          >
            <template #reference>
              <el-button class="follow-btn" @click="followIdea">{{
                $t("followIdea")
              }}</el-button>
            </template>
          </el-popover>
          <el-button
            class="follow-btn"
            @click="unfollowIdea"
            v-if="IdeaItem.IsFollow"
            >{{ $t("unfollowIdea") }}</el-button
          >
        </div>
      </div>
      <el-menu
        default-active="1"
        mode="horizontal"
        :ellipsis="false"
        class="custom-menu"
        @select="handleTabChange"
      >
        <el-menu-item index="1" class="custom-menuitem"
          >{{ $t("idea") }}
          <img src="@/assets/icons/Ellipse.svg" v-show="false"
        /></el-menu-item>
        <el-menu-item index="2" class="custom-menuitem"
          >{{ $t("comments") }}
          <img src="@/assets/icons/Ellipse.svg" v-show="true"
        /></el-menu-item>
      </el-menu>
    </section>

    <!-- 内容部分 -->
    <section class="content" v-if="currentTab == 1">
      <div class="content-left" v-html="IdeaItem.Idea.Description"></div>
      <div class="content-right">
        <div class="label-text">{{ $t("status") }}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="0.5rem"
          height="0.5rem"
          viewBox="0 0 8 9"
          :fill="getStatusColor(IdeaItem.Idea.Status)"
        >
          <circle cx="4" cy="4.5" r="4" />
        </svg>
        <span
          class="status-text"
          :style="`color: ${getStatusColor(IdeaItem.Idea.Status)}`"
        >
          &nbsp;{{ getStatusText(IdeaItem.Idea.Status) }}</span
        >
        <div class="label-text" style="margin-top: 2rem">
          {{ $t("submitted") }}
        </div>
        <span class="submittime-text">
          {{ formatTimeAgo(IdeaItem.Idea.CreatedOn) }}</span
        >
        <div class="label-text" style="margin-top: 2rem; display: flex">
          {{ $t("attachments") }}
          <div class="attachments-num">
            {{ IdeaItem.AttachmentList?.length || 0 }}
          </div>
        </div>
        <el-upload
          v-model:file-list="AttFileList"
          disabled
          :on-preview="handleAttPreview"
          style="
            --el-color-primary: #f36633;
            margin-top: -1.5rem;
            margin-left: -0.5rem;
          "
        ></el-upload>
      </div>
    </section>

    <!-- 评论部分 -->
    <section class="content" v-if="currentTab == 2">
      <div class="content-left">
        <div
          v-for="(item, index) in commentData"
          :key="index"
          class="comment-item"
        >
          <div class="userinfo-bar">
            <div class="userinfo">
              <el-avatar
                :src="'data:image/jpeg;base64,' + item.User.Avatar"
                :size="40"
              ></el-avatar>
              <div style="margin-left: 1rem">
                <div class="username">{{ item.User.UserName }}</div>
                <div class="createtime">
                  {{ formatTimeAgo(item.Comment.CreatedOn) }}
                </div>
              </div>
            </div>
            <el-popover
              placement="bottom-end"
              trigger="hover"
              popper-class="custom-popper"
              v-if="
                getUserInfo()?.graphUserInfo!.UserId == item.Comment.CreatedBy
              "
            >
              <template #reference>
                <el-icon style="color: #484851; cursor: pointer">
                  <MoreFilled />
                </el-icon>
              </template>
              <template #default>
                <div
                  style="
                    width: 100%;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                  "
                >
                  <button
                    class="custom-btn"
                    @click="DeleteComment(item.Comment.CommId)"
                  >
                    {{ $t("delete") }}
                  </button>
                </div>
              </template>
            </el-popover>
          </div>
          <div class="comment-content">
            <div class="root-comment">
              {{ item.Comment.Content }}
            </div>
            <div style="display: flex; gap: 1rem">
              <div
                class="action-bar"
                @click="
                  item.IsLike
                    ? CommentLikeCancel(item.Comment.CommId)
                    : CommentLike(item.Comment.CommId)
                "
              >
                <img src="@/assets/icons/zan-fill.svg" v-if="item.IsLike" />
                <img src="@/assets/icons/zan.svg" v-if="!item.IsLike" />
                <span class="action-num">{{ item.Comment.LikeCount }}</span>
              </div>
              <span
                class="reply-button"
                @click="handleReplyClick(item.Comment.CommId)"
                >{{ $t("reply") }}</span
              >
            </div>
          </div>
          <div class="child-comment">
            <div
              class="child-comment-content"
              v-for="(reply, reply_index) in item.Reply"
              :key="reply_index"
            >
              <div class="userinfo-bar" style="padding: 0">
                <div class="userinfo">
                  <el-avatar
                    :src="'data:image/jpeg;base64,' + reply.User.Avatar"
                    :size="40"
                  ></el-avatar>
                  <div style="margin-left: 1rem">
                    <div class="username">
                      {{ reply.User.UserName }}
                      <span class="createtime">
                        <el-icon>
                          <CaretRight />
                        </el-icon>
                      </span>
                      {{ reply.PareCommUser.UserName }}
                    </div>
                    <div class="createtime">
                      {{ formatTimeAgo(reply.Comment.CreatedOn) }}
                    </div>
                  </div>
                </div>
                <el-popover
                  placement="bottom-end"
                  trigger="click"
                  popper-class="custom-popper"
                  v-if="
                    getUserInfo()?.graphUserInfo!.UserId ==
                    reply.Comment.CreatedBy
                  "
                >
                  <template #reference>
                    <el-icon style="color: #484851; cursor: pointer">
                      <MoreFilled />
                    </el-icon>
                  </template>
                  <template #default>
                    <div
                      style="
                        width: 100%;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                      "
                    >
                      <button
                        class="custom-btn"
                        @click="DeleteComment(reply.Comment.CommId)"
                      >
                        {{ $t("delete") }}
                      </button>
                    </div>
                  </template>
                </el-popover>
              </div>
              <div style="margin-left: 3.5rem; margin-top: 1rem">
                {{ reply.Comment.Content }}
              </div>
              <div
                style="
                  display: flex;
                  gap: 1rem;
                  margin-left: 3.5rem;
                  margin-top: 1rem;
                "
              >
                <div
                  class="action-bar"
                  @click="
                    reply.IsLike
                      ? CommentLikeCancel(reply.Comment.CommId)
                      : CommentLike(reply.Comment.CommId)
                  "
                >
                  <img
                    src="@/assets/icons/zan-fill.svg"
                    v-show="reply.IsLike"
                  />
                  <img src="@/assets/icons/zan.svg" v-show="!reply.IsLike" />
                  <span class="action-num">{{ reply.Comment.LikeCount }}</span>
                </div>
                <span
                  class="reply-button"
                  @click="
                    handleReplyClick(item.Comment.CommId, reply.Comment.CommId)
                  "
                  >{{ $t("reply") }}</span
                >
              </div>
            </div>
            <div
              style="display: flex; padding-top: 1rem; padding-bottom: 1rem"
              v-if="item.Reply && item.commentCard"
            >
              <el-avatar
                :src="'data:image/jpeg;base64,' + getUserInfo()!.graphUserInfo!.avatar"
                :size="40"
              ></el-avatar>
              <div style="width: 100%">
                <el-input
                  v-model="commentText"
                  :autosize="{ minRows: 3 }"
                  type="textarea"
                  placeholder="Please input"
                  style="margin-left: 1rem; --el-color-primary: #f36633"
                />
                <div style="display: flex; margin-left: 1rem">
                  <el-button
                    class="submit-btn"
                    @click="
                      handleChildCommentSubmit(
                        commentData[0].Comment.IdeaId,
                        item.Reply[0]
                          ? item.Reply[0].Comment.RootCommId
                          : item.Comment.CommId,
                        item.Comment.Content
                      )
                    "
                    >{{ $t("submitLower") }}</el-button
                  >
                  <el-button
                    class="cancel-btn"
                    color="white
      "
                    @click="handleCancelClick"
                    >{{ $t("cancel") }}</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="right-title">{{ $t("users") }}</div>
        <div class="user-avatar-group">
          <el-avatar
            v-for="(avatar, index) in avatarArray.slice(0, 5)"
            :key="index"
            :src="'data:image/jpeg;base64,' + avatar"
          ></el-avatar>
          <div class="num-round">{{ avatarArray.length }}</div>
        </div>
        <div class="right-title">{{ $t("yourSuggestions") }}</div>
        <el-input
          v-model="suggestions"
          :rows="3"
          type="textarea"
          :placeholder="$t('yourSuggestionsMsg')"
          style="margin-top: 0.5rem; --el-color-primary: #f36633"
        />
        <el-button
          class="submit-btn"
          :disabled="!suggestions"
          @click="handleSuggestionsSubmit(IdeaItem.Idea.IdeaId)"
          >{{ $t("submitLower") }}</el-button
        >
        <el-button class="cancel-btn" @click="suggestions = ''">{{
          $t("clear")
        }}</el-button>
      </div>
    </section>

    <!-- Footer部分 -->
    <section class="footer">{{ $t("aboutGSKMsg") }}</section>
  </div>
</template>

<style scoped>
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.banner-container {
  position: relative;
  background-size: cover;
  background-position: 10%;
  width: 100%;
  height: 35vh;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(5px);
  margin-bottom: 1rem;
}

.rectangle {
  position: absolute;
  display: flex;
  justify-content: end;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 76px;
  background-image: url("@/assets/images/index/Rectangle.png");
  background-position: center;
  background-size: cover;
  align-items: start;
}

.creator-info {
  position: absolute;
  display: flex;
  top: -1rem;
  margin-right: 7rem;
  display: flex;
  gap: 1rem;
}

.el-avatar {
  border: 2px solid rgba(174, 174, 183, 0.2);
}

.creator-name {
  margin-top: 1.2rem;
  color: #484851;
  text-align: right;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}

.titlebar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  width: 100%;
}

:deep(.el-page-header) {
  width: 100%;
}

:deep(.el-page-header__back .el-page-header__icon) {
  color: #868693;
  font-size: 2rem;
}

:deep(.el-page-header__back .el-page-header__title) {
  font-size: 2rem;
  color: #3c3c43;
  font-family: GSK Precision;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
}

.ideaheader-container {
  padding-left: 7rem;
  padding-right: 7rem;
  position: sticky;
  top: 3rem;
  z-index: 999;
  background-color: white;
}

.tool-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.icon-button {
  display: flex;
  align-items: center;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  gap: 0.75rem;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.icon-text {
  color: #868693;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.follow-btn {
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  font-family: "GSKPrecision";
  background-color: white;
  color: #484851;
  border-radius: 6px;
  --el-button-border-color: #c9c9cf !important;
  --el-button-hover-text-color: #376f7b !important;
  --el-button-active-text-color: #376f7b !important;
  --el-button-hover-bg-color: rgba(55, 111, 123, 0.1) !important;
  --el-button-active-bg-color: rgba(55, 111, 123, 0.1) !important;
  --el-button-hover-border-color: #376f7b !important;
  --el-button-active-border-color: #376f7b !important;
  font-style: normal;
  font-weight: 400;
}

.custom-menu {
  background-color: transparent;
}

.custom-menu .el-menu-item {
  color: #484851 !important;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
}

.custom-menu .el-menu-item:hover,
.custom-menu .el-menu-item:focus,
.custom-menu .el-menu-item:active {
  background-color: transparent !important;
  /* Remove hover and click background color */
  color: #f36633 !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #f36633 !important;
  border-color: #f36633 !important;
}

.custom-menu .el-menu-item.is-active {
  color: inherit;
  /* Remove active color */
}

.custom-menuitem {
  gap: 0.5rem;
}

.content {
  display: flex;
  padding-left: 7rem;
  padding-right: 7rem;
  box-sizing: border-box;
  min-height: 60vh;
  margin-top: 2.5rem;
  margin-bottom: 5rem;
  width: 100%;
  gap: 5rem;
}

.content-left {
  flex: 3 1 0;
  /* flex-grow flex-shrink flex-basis */
  box-sizing: border-box;
}

.content-right {
  flex: 1 1 0;
  /* flex-grow flex-shrink flex-basis */
  box-sizing: border-box;
}

.label-text {
  color: #3c3c43;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0.75rem;
}

.status-text {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
}

.submittime-text {
  color: #868693;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}

.attachments-num {
  margin-left: 0.75rem;
  display: flex;
  height: 24px;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #868693;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
}

.footer {
  height: 120px;
  width: 100%;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #484851;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.comment-item:not(:last-child) {
  border-bottom: rgba(201, 201, 207, 0.5) 1px solid;
}

.comment-item:not(:first-child) {
  padding-top: 1rem;
}

.submit-btn {
  margin-top: 1rem;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  height: 2rem;
  padding: 0rem 1rem;
  border-radius: 0.375rem;
  font-family: GSK Precision;
  --el-button-bg-color: #f36633 !important;
  --el-button-border-color: #f36633 !important;
  --el-button-hover-bg-color: #376f7b !important;
  --el-button-hover-text-color: white !important;
  --el-button-hover-border-color: #376f7b !important;
  --el-button-active-bg-color: #376f7b !important;
  --el-button-active-border-color: #376f7b !important;
}

.cancel-btn {
  margin-top: 1rem;
  color: #484851;
  text-align: center;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  height: 2rem;
  padding: 0rem 1rem;
  border-radius: 0.375rem;
  font-family: GSK Precision;
  --el-button-border-color: #c9c9cf !important;
  --el-button-hover-border-color: #c9c9cf !important;
  --el-button-active-border-color: #c9c9cf !important;
  --el-button-hover-text-color: #484851 !important;
  --el-button-active-text-color: #484851 !important;
}

.child-comment {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
  background: #f6f6f6;
  margin-left: 3.5rem;
  padding-right: 2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.child-comment-content {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.child-comment-content:not(:last-child) {
  border-bottom: rgba(201, 201, 207, 0.5) 1px solid;
}

.root-comment {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.comment-content {
  padding-left: 3.5rem;
  padding-right: 2rem;
}

.userinfo-bar {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-right: 2rem;
  box-sizing: border-box;
}

.reply-button {
  color: #868693;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  cursor: pointer;
}

.userinfo {
  display: flex;
}

.username {
  color: #484851;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3125rem;
}

.createtime {
  color: #aeaeb7;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
}

.right-title {
  color: #3c3c43;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;
  /* 150% */
}

.user-avatar-group {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  display: flex;
}

.user-avatar-group .el-avatar {
  margin-right: -0.5rem;
}

.num-round {
  margin-right: -0.5rem;
  border-radius: 1.875rem;
  background: #c9c9cf;
  width: 40px;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: GSK Precision;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
}

.action-num {
  margin-left: 0.25rem;
  color: #868693;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  /* 150% */
}

.action-bar {
  display: flex;
  align-items: center;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  cursor: pointer;
}

.custom-btn {
  width: 100%;
  color: #484851;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  /* 150% */
  border: none;
  background-color: transparent;
  font-family: GSK Precision;
  text-align: left;
  height: 2rem;
  cursor: pointer;
}

.el-popover.el-popper.custom-popper {
  min-width: 80px !important;
  width: 80px !important;
  padding: 0 !important;
  border-radius: 0.5rem !important;
  border: 1px solid #c9c9cf !important;
  box-shadow: 0px 0px 8px 0px rgba(60, 60, 67, 0.2) !important;
}

.el-button.is-disabled {
  color: var(--el-button-disabled-text-color) !important;
}

@media only screen and (max-width: 600px) {
  .creator-info {
    position: relative;
    margin-right: 1rem;
    margin-top: 0.5rem;
  }
  .rectangle {
    height: auto;
  }
  .ideaheader-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .content {
    padding-left: 1rem;
    padding-right: 1rem;
    display: block;
  }
  .content-left {
    margin-bottom: 2.5rem;
  }
  .titlebar-container {
    display: block;
  }
  .tool-container {
    margin-top: 1rem;
  }
}
</style>

<script lang="ts">
import HeaderBar from "@/components/HeaderBar.vue";
import { ArrowLeft, CaretRight } from "@element-plus/icons-vue";
import { onMounted, inject, ref } from "vue";
import { useRouter } from "vue-router";
import { AxiosInstance } from "axios";
import { MoreFilled } from "@element-plus/icons-vue";
import { getUserInfo } from "@/state/UserInfo";
import { getStatusText } from "@/utils/getStatusText";
import { getStatusColor } from "@/utils/getStatusColor";
import type { UploadUserFile, UploadProps } from "element-plus";
import { getSasTokenFromCache } from "@/state/getSasToken";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";

export default {
  components: {
    HeaderBar,
  },
  setup() {
    const router = useRouter();
    const axiosInstance = inject("axiosInstance") as AxiosInstance;
    interface Attachment {
      AttId: number;
      AttName: string;
      AttPath: string;
      BlobFileName: string;
      ContainerName: string;
      CreatedBy: number;
      CreatedOn: string;
      DownloadCount: number;
      IdeaId: number;
      State: number;
      Type: number;
      SceneType: number;
    }
    interface IdeaItem_ {
      Idea: Idea;
      IsCollect: boolean;
      IsLike: boolean;
      IsFollow: boolean;
      User: User;
      AttachmentList: Attachment[];
    }

    interface Idea {
      CollectCount: number;
      CommentCount: number;
      CreatedBy: number;
      CreatedOn: string;
      Description: string;
      IdeaId: number;
      LikeCount: number;
      ModifiedBy: number;
      ModifiedOn: string;
      Owner: number;
      PlaygroundCreatedBy: number;
      PlaygroundCreatedOn: string;
      PlaygroundModifiedBy: number;
      PlaygroundModifiedOn: string;
      State: number;
      Status: number;
      SubmittedOn: string;
      Title: string;
      BannerImgUrl: string;
    }

    interface User {
      Avatar: string;
      UserId: number;
      UserName: string;
    }

    interface Comment {
      CommId: number;
      Content: string;
      CreatedBy: number;
      CreatedOn: string;
      IdeaId: number;
      LikeCount: number;
      ModifiedBy: number;
      ModifiedOn: string;
      PareCommId: number;
      State: number;
      RootCommId: number;
    }

    interface CommentData {
      IsLike: boolean;
      Comment: Comment;
      User: User;
      Reply: Reply[];
      commentCard: boolean;
    }

    interface Reply {
      Comment: Comment;
      User: User;
      IsLike: boolean;
      PareCommUser: PareCommUser;
    }

    interface PareCommUser {
      Authority: number;
      Email: string;
      IdeaCount: number;
      MudId: string;
      PageCount: number;
      PendingAdmit: boolean;
      RowCount: number;
      UserId: number;
      UserName: string;
    }

    const IdeaItem = ref<IdeaItem_>({
      Idea: {
        CollectCount: 0,
        CommentCount: 0,
        CreatedBy: 0,
        CreatedOn: "",
        Description: "",
        IdeaId: 0,
        LikeCount: 0,
        ModifiedBy: 0,
        ModifiedOn: "",
        Owner: 0,
        PlaygroundCreatedBy: 0,
        PlaygroundCreatedOn: "",
        PlaygroundModifiedBy: 0,
        PlaygroundModifiedOn: "",
        State: 0,
        Status: 0,
        SubmittedOn: "",
        Title: "",
        BannerImgUrl: "",
      },
      IsCollect: false,
      IsLike: false,
      IsFollow: false,
      User: {
        Avatar: "",
        UserId: 0,
        UserName: "",
      },
      AttachmentList: [],
    });
    const { t } = useI18n();
    const commentContent = ref("");
    const suggestions = ref("");
    const commentData = ref<CommentData[]>([]);
    const currentCommId = ref(0);
    const currentTab = ref(1);
    const commentText = ref("");
    const avatarArray = ref([] as string[]);
    onMounted(async () => {
      await getIdea(Number(router.currentRoute.value.params.id));
      await getCommentList(Number(router.currentRoute.value.params.id));
      getAttFileList();
    });
    /* eslint-disable */
    const formatTimeAgo = inject("formatTimeAgo") as (
      timestamp: string
    ) => string;
    const getIdea = (IdeaId: Number) => {
      return axiosInstance
        .request({
          url: "api/Idea/GetIdea",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
            IdeaId: IdeaId,
          },
          method: "get",
        })
        .then((res) => {
          IdeaItem.value = res.data.Data;
          console.log("getIdea", res);
        });
    };

    const handleCollectClick = async (IdeaId: number) => {
      if (IdeaItem.value.IsCollect) {
        await ideaCollectCancel(IdeaId);
      } else {
        await ideaCollect(IdeaId);
      }
      setTimeout(() => {
        getIdea(IdeaId);
      }, 1000); // Delay of 1 second
    };

    const handleLikeClick = async (IdeaId: number) => {
      if (IdeaItem.value.IsLike) {
        await ideaLikeCancel(IdeaId);
      } else {
        await ideaLike(IdeaId);
      }
      setTimeout(() => {
        getIdea(IdeaId);
      }, 1000); // Delay of 1 second
    };

    const followIdea = () => {
      return axiosInstance
        .request({
          url: "api/Idea/IdeaFollow",
          data: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
            IdeaId: IdeaItem.value.Idea.IdeaId,
          },
          method: "post",
        })
        .then((res) => {
          console.log("followIdea", res);
          getIdea(Number(router.currentRoute.value.params.id));
        });
    };

    const unfollowIdea = () => {
      return axiosInstance
        .request({
          url: "api/Idea/IdeaFollowCancel",
          data: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
            IdeaId: IdeaItem.value.Idea.IdeaId,
          },
          method: "post",
        })
        .then((res) => {
          console.log("unfollowIdea", res);
          getIdea(Number(router.currentRoute.value.params.id));
        });
    };
    const goBack = () => {
      router.push("/browse");
    };

    const ideaCollect = (IdeaId: number) => {
      axiosInstance
        .request({
          url: "api/Idea/IdeaCollect",
          data: {
            ideaId: IdeaId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log("ideaCollect", res);
        });
    };

    const ideaCollectCancel = (IdeaId: number) => {
      axiosInstance
        .request({
          url: "api/Idea/IdeaCollectCancel",
          data: {
            ideaId: IdeaId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log("IdeaCollectCancel", res);
        });
    };

    const ideaLike = (IdeaId: number) => {
      axiosInstance
        .request({
          url: "api/Idea/IdeaLike",
          data: {
            ideaId: IdeaId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log("ideaLike", res);
        });
    };

    const ideaLikeCancel = (IdeaId: number) => {
      axiosInstance
        .request({
          url: "api/Idea/IdeaLikeCancel",
          data: {
            ideaId: IdeaId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log("ideaLikeCancel", res);
        });
    };

    const CommentLike = (CommId: number) => {
      axiosInstance
        .request({
          url: "api/Comment/CommentLike",
          data: {
            CommId: CommId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log(res);
          getCommentList(Number(router.currentRoute.value.params.id));
        });
    };

    const CommentLikeCancel = (CommId: number) => {
      axiosInstance
        .request({
          url: "api/Comment/CommentLikeCancel",
          data: {
            CommId: CommId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log(res);
          getCommentList(Number(router.currentRoute.value.params.id));
        });
    };

    const handleTabChange = (e: number) => {
      currentTab.value = e;
    };

    const getCommentList = (IdeaId: number) => {
      return axiosInstance
        .request({
          url: "api/Comment/GetCommentList",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
            IdeaId: IdeaId,
            PageSize: 10,
            PageIndex: 1,
          },
          method: "get",
        })
        .then((res) => {
          let tempCommentData = res.data.Data;
          tempCommentData.forEach(function (comment: CommentData) {
            comment.commentCard = false;
          });
          commentData.value = tempCommentData;
          commentData.value.forEach(function (comment: CommentData) {
            if (!avatarArray.value.includes(comment.User.Avatar)) {
              avatarArray.value.push(comment.User.Avatar);
            }
            comment.Reply.forEach(function (reply: Reply) {
              if (!avatarArray.value.includes(reply.User.Avatar)) {
                avatarArray.value.push(reply.User.Avatar);
              }
            });
          });
          console.log("test", commentData);
        });
    };

    const handleReplyClick = (
      parentCommId: number,
      childCommId: number | null = null
    ) => {
      commentData.value.forEach(function (comment: CommentData) {
        comment.commentCard = false;
        if (comment.Comment.CommId === parentCommId) {
          comment.commentCard = true;
        }
      });
      if (childCommId) {
        currentCommId.value = childCommId;
      } else {
        currentCommId.value = parentCommId;
      }
    };

    const handleChildCommentSubmit = (
      IdeaId: number,
      RootCommId: number,
      Content: string
    ) => {
      addComment(
        IdeaId,
        currentCommId.value,
        RootCommId,
        commentText.value,
        Content
      );
    };

    const handleSuggestionsSubmit = (IdeaId: number) => {
      addComment(IdeaId, 0, 0, suggestions.value);
    };

    const addComment = (
      IdeaId: number,
      ParentCommId: number,
      RootCommId: number,
      commentContent: string,
      ParentCommentContent?: string
    ) => {
      axiosInstance
        .request({
          url: "api/Comment/AddComment",
          data: {
            pareCommId: ParentCommId,
            ideaId: IdeaId,
            content: commentContent,
            RootCommId: RootCommId,
            IdeaOwnerId: IdeaItem.value.Idea.Owner,
            IdeaTitle: IdeaItem.value.Idea.Title,
            CreatedBy: getUserInfo()!.graphUserInfo!.UserId,
            ParentCommentContent: ParentCommentContent,
          },
          method: "post",
        })
        .then((res) => {
          console.log("Add Comment Successfully.", res);
          getCommentList(IdeaId);
          getIdea(Number(router.currentRoute.value.params.id));
          commentText.value = "";
          suggestions.value = "";
        });
    };
    const handleCancelClick = () => {
      commentData.value.forEach(function (comment: CommentData) {
        comment.commentCard = false;
      });
      commentText.value = "";
    };
    const AttFileList = ref<UploadUserFile[]>([]);
    const getAttFileList = () => {
      if (
        !IdeaItem.value.AttachmentList ||
        !IdeaItem.value.AttachmentList.every(
          (att: Attachment) => att.SceneType !== 2
        )
      )
        return;

      IdeaItem.value.AttachmentList.forEach(function (att: Attachment) {
        AttFileList.value.push({
          name: att.AttName,
          url: att.AttPath + "?" + getSasTokenFromCache(),
        });
      });

      console.log(AttFileList.value);
    };

    const handleAttPreview: UploadProps["onPreview"] = (file) => {
      window.open(file.url, "_blank");
    };

    const DeleteComment = (CommId: number) => {
      ElMessageBox.confirm(`${t("warningmsg")}`, `${t("warning")}`, {
        confirmButtonText: `${t("confirm")}`,
        cancelButtonText: `${t("cancel")}`,
        type: "warning",
      })
        .then(() => {
          axiosInstance
            .request({
              url: "api/Comment/DeleteComment",
              data: {
                CommId: CommId,
                UserId: getUserInfo()!.graphUserInfo!.UserId,
              },
              method: "post",
            })
            .then((res) => {
              getCommentList(IdeaItem.value.Idea.IdeaId);
              getIdea(Number(router.currentRoute.value.params.id));
              ElMessage({
                type: "success",
                message: `${t("deletesuccess")}`,
                offset: 100,
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: `${t("deletecancel")}`,
            offset: 100,
          });
        });
    };
    return {
      IdeaItem,
      currentTab,
      commentData,
      commentContent,
      commentText,
      suggestions,
      avatarArray,
      AttFileList,
      handleCollectClick,
      handleLikeClick,
      followIdea,
      unfollowIdea,
      goBack,
      getStatusText,
      getStatusColor,
      formatTimeAgo,
      handleTabChange,
      handleReplyClick,
      handleChildCommentSubmit,
      handleCancelClick,
      handleSuggestionsSubmit,
      getUserInfo,
      handleAttPreview,
      CommentLike,
      CommentLikeCancel,
      getSasTokenFromCache,
      DeleteComment,
      ArrowLeft,
      MoreFilled,
      CaretRight,
    };
  },
};
</script>
