<template>
    <div class="footer_bar">
        <router-link to="/" custom v-slot="{ navigate, href }">
            <div class="menu_item" :class="{ active: activeItem === 1 }" @click="navigate" :href="href">
                <img :src="activeItem === 1 ? homeActive : home" />
                <div>{{ $t('home') }}</div>
            </div>
        </router-link>
        <router-link to="/submit" custom v-slot="{ navigate, href }">
            <div class="menu_item" :class="{ active: activeItem === 2 }" @click="navigate" :href="href">
                <img :src="activeItem === 2 ? submitActive : submit" />
                <div>{{ $t('submit') }}</div>
            </div>
        </router-link>
        <router-link to="/browse" custom v-slot="{ navigate, href }">
            <div class="menu_item" :class="{ active: activeItem === 3 }" @click="navigate" :href="href">
                <img :src="activeItem === 3 ? browseActive : browse" />
                <div>{{ $t('browse') }}</div>
            </div>
        </router-link>
    </div>
</template>

<style scoped>
.footer_bar {
    background-color: white;
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: 4rem;
    padding-bottom: 1rem;
}

.menu_item {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: #1e293b;
    gap: 5px;
}

.menu_item.active {
    color: #f36633;
}
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: "FooterBar",
    props: {
        activeItem: {
            type: Number,
            default: 0
        }
    },
    setup() {
        const home = require("@/assets/icons/home.svg")
        const homeActive = require("@/assets/icons/home-fill.svg")
        const submit = require("@/assets/icons/edit.svg")
        const submitActive = require("@/assets/icons/edit-fill.svg")
        const browse = require("@/assets/icons/bulb.svg")
        const browseActive = require("@/assets/icons/bulb-fill.svg")

        return {
            home,
            homeActive,
            submit,
            submitActive,
            browse,
            browseActive,
        }
    }
})
</script>
