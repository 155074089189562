export function getStatusText(Status: number): string {
  switch (Status) {
    case 1:
      return "NEW";
    case 2:
      return "DISCUSSION";
    case 3:
      return "BUILDING";
    case 4:
      return "PILOT";
    default:
      return "NEW";
  }
}
