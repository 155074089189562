export function getStatusColor(Status: number): string {
  switch (Status) {
    case 1:
      return "#f36633";
    case 2:
      return "#00c27c";
    case 3:
      return "#007cc2";
    case 4:
      return "#8535eb";
    default:
      // Default style
      return "black";
  }
}
