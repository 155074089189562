<template>
  <router-view />
</template>

<style>
body,
html {
  margin: 0;
  padding: 0;
  font-family: "GSKPrecision";
}

.bold-text {
  font-weight: bold;
}

.italic-text {
  font-style: italic;
}

.light-text {
  font-weight: 300;
}

.thin-text {
  font-weight: 100;
}

@font-face {
  font-family: "GSKPrecision";
  src: url("@/assets/fonts/GSKPrecision-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GSKPrecision";
  src: url("@/assets/fonts/GSKPrecision-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "GSKPrecision";
  src: url("@/assets/fonts/GSKPrecision-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GSKPrecision";
  src: url("@/assets/fonts/GSKPrecision-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "GSKPrecision";
  src: url("@/assets/fonts/GSKPrecision-Light.ttf") format("truetype");
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: "GSKPrecision";
  src: url("@/assets/fonts/GSKPrecision-LightItalic.ttf") format("truetype");
  font-weight: 300; /* Light */
  font-style: italic;
}

@font-face {
  font-family: "GSKPrecision";
  src: url("@/assets/fonts/GSKPrecision-Thin.ttf") format("truetype");
  font-weight: 100; /* Thin */
  font-style: normal;
}

@font-face {
  font-family: "GSKPrecision";
  src: url("@/assets/fonts/GSKPrecision-ThinItalic.ttf") format("truetype");
  font-weight: 100; /* Thin */
  font-style: italic;
}
</style>
