<template>
  <div id="app"></div>
</template>

<script lang="ts">
import { ref, onMounted, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElLoading } from "element-plus";
import { getUserInfo, saveUserInfo, UserInfo } from "@/state/UserInfo";
import { AxiosInstance } from "axios";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const code = ref(route.query.code).value;
    const axiosInstance = inject("axiosInstance") as AxiosInstance;

    onMounted(async () => {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(256, 256, 256, 0.7)",
      });

      try {
        if (code != undefined && code != "") {
          axiosInstance
            .request({
              url: "api/Token/GetWecomUserId",
              params: {
                code: code,
              },
              method: "get",
            })
            .then(async (res) => {
              console.log("WecomView:", res);

              let userInfo = getUserInfo();

              if (!userInfo) {
                userInfo = {} as UserInfo;
              }
              if (res.data.userInfo.userId == 0) {
                userInfo.wecom_userid = res.data.userInfo.weComId;
                await saveUserInfo(userInfo);
                router.push("/login");
              } else {
                userInfo.wecom_userid = res.data.userInfo.weComId;
                userInfo.tokenResult = res.data.tokenResult;
                userInfo.graphUserInfo = res.data.userInfo;
                axiosInstance.interceptors.request.use((config) => {
                  const token = res.data.tokenResult.access_token;
                  if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                  }
                  return config;
                });
                await saveUserInfo(userInfo);
                router.push("/");
              }
            });
        }
      } catch (error) {
        console.error(error);
      } finally {
        loading.close();
      }
    });
    return {
      code,
    };
  },
};
</script>
