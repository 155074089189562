<template>
  <div class="newscard-container" @click="handleCardClick">
    <div class="avatar-container">
      <el-avatar
        :src="'data:image/jpeg;base64,' + ideaItem.User.Avatar"
        class="custom-avatar"
      ></el-avatar>
    </div>
    <div class="content-container">
      <div class="basicinfo-container">
        <div>
          <span class="username">{{ ideaItem.User.UserName }}</span>
          <span class="publish-time">{{
            formatTimeAgo(ideaItem.Idea.CreatedOn)
          }}</span>
        </div>
      </div>
      <div class="detail-container">
        <img
          :src="ideaItem.Idea.BannerImgUrl + '?' + getSasTokenFromCache()"
          class="news-pic"
          v-if="ideaItem.Idea.BannerImgUrl"
        />
        <div class="content">
          <div class="title-row">
            <div class="title-text">
              <span class="idea-text">{{ $t("idea") }}</span>
              <span class="idea-title"
                ><span class="grey-text">" </span>{{ ideaItem.Idea.Title
                }}<span class="grey-text"> "</span></span
              >
            </div>
            <PostActions :card="ideaItem" :customGap="'1rem'" />
          </div>
          <div class="news-detail">
            {{ removeHtmlTags(ideaItem.Idea.Description) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.newscard-container {
  margin: 0 auto;
  padding-bottom: 1rem;
  margin-top: 1.5rem;
  width: 90%;
  display: flex;
  border-bottom: 1px solid rgba(211, 211, 211, 0.5);
  cursor: pointer;
  min-height: 9rem;
  box-sizing: border-box;
}

.avatar-container {
  padding-right: 16px;
  width: 40px;
  height: 100%;
}

.el-avatar {
  border: 2px solid rgba(174, 174, 183, 0.2);
}

.basicinfo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.username {
  color: #484851;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.publish-time {
  margin-left: 8px;
  color: #aeaeb7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.news-pic {
  width: 8rem;
  height: 6rem;
  border-radius: 8px;
  margin-right: 1rem;
}

.detail-container {
  margin-top: 12px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

.content {
  width: 100%;
}
.title-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title-text {
  display: flex;
  align-items: center;
  width: 100%;
}

.idea-text {
  color: #868693;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-right: 8px;
}

.idea-title {
  color: #376f7b;
  width: 85%;
  /* 16px-bold */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grey-text {
  color: #868693;
}

.content-container {
  width: 100%;
}

.actions {
  display: flex;
  gap: 10px; /* 设置图标之间的间距 */
}

.news-detail {
  color: #484851;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.5rem;
}

.icon {
  width: 16px;
  height: 16px;
}

.icon-text {
  color: #868693;
  /* 12px */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

@media only screen and (max-width: 600px) {
  .newscard-container {
    width: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
</style>

<script lang="ts">
import { defineComponent, PropType, inject } from "vue";
import { useRouter } from "vue-router";
import PostActions from "@/components/PostActions.vue";
import { removeHtmlTags } from "@/utils/removeHtmlTags";
import { getSasTokenFromCache } from "@/state/getSasToken";
interface IdeaItem {
  Idea: Idea;
  IsCollect: boolean;
  IsLike: boolean;
  User: User;
}

interface Idea {
  CollectCount: number;
  CommentCount: number;
  CreatedBy: number;
  CreatedOn: string;
  Description: string;
  IdeaId: number;
  LikeCount: number;
  ModifiedBy: number;
  ModifiedOn: string;
  Owner: number;
  PlaygroundCreatedBy: number;
  PlaygroundCreatedOn: string;
  PlaygroundModifiedBy: number;
  PlaygroundModifiedOn: string;
  State: number;
  Status: number;
  SubmittedOn: string;
  Title: string;
  BannerImgUrl: string;
}

interface User {
  Avatar: string;
  UserId: number;
  UserName: string;
}

export default defineComponent({
  name: "NewsCard",
  props: {
    ideaItem: {
      type: Object as PropType<IdeaItem>,
      required: true,
    },
  },
  components: {
    PostActions,
  },
  setup(props) {
    /* eslint-disable */
    const formatTimeAgo = inject("formatTimeAgo") as (
      timestamp: string
    ) => string;
    const router = useRouter();
    const handleCardClick = () => {
      router.push(`/ideas/${props.ideaItem.Idea.IdeaId}`);
    };
    return {
      getSasTokenFromCache,
      formatTimeAgo,
      handleCardClick,
      removeHtmlTags,
    };
  },
});
</script>
