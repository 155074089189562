<template>
  <div class="postaction-bar" :style="{ gap: customGap }">
    <button
      @click.stop="handleCollectClick(localCard.Idea.IdeaId)"
      class="icon-button"
      id="star"
    >
      <img
        src="@/assets/icons/star.svg"
        class="icon"
        v-show="!localCard.IsCollect"
      />
      <img
        src="@/assets/icons/star-fill.svg"
        class="icon"
        v-show="localCard.IsCollect"
      />
      <span class="icon-text" style="margin-left: 4px">{{
        localCard.Idea.CollectCount
      }}</span></button
    ><!-- 点赞图标 -->
    <button
      @click.stop="handleLikeClick(localCard.Idea.IdeaId)"
      class="icon-button"
      id="zan"
    >
      <img
        src="@/assets/icons/zan.svg"
        class="icon"
        v-show="!localCard.IsLike"
      />
      <img
        src="@/assets/icons/zan-fill.svg"
        class="icon"
        v-show="localCard.IsLike"
      />
      <span class="icon-text" style="margin-left: 4px">{{
        localCard.Idea.LikeCount
      }}</span>
    </button>
    <!-- 评论图标 -->
    <button class="icon-button" id="comment">
      <img src="@/assets/icons/comment.svg" class="icon" />
      <span class="icon-text" style="margin-left: 4px">{{
        localCard.Idea.CommentCount
      }}</span>
    </button>
  </div>
</template>

<style>
.postaction-bar {
  display: flex;
  align-items: center;
  gap: 10px; /* 设置图标之间的间距 */
}
.icon-button {
  display: flex;
  align-items: center;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.icon {
  width: 16px;
  height: 16px;
}
.icon-text {
  color: #868693;
  /* 12px */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

@media only screen and (max-width: 600px) {
  .postaction-bar {
    gap: 5px !important;
  }
  #comment {
    display: none;
  }
  #zan {
    display: none;
  }
}
</style>

<script lang="ts">
/* eslint-disable */
import { inject, defineComponent, reactive, watch } from "vue";
import { AxiosInstance } from "axios";
import { useRouter } from "vue-router";
import { getUserInfo } from "@/state/UserInfo";

export default defineComponent({
  name: "PostActions",
  props: {
    card: {
      type: Object,
      required: true,
    },
    customGap: {
      type: String,
      default: "10px",
      required: false,
    },
  },
  setup(props, context) {
    const axiosInstance = inject("axiosInstance") as AxiosInstance;
    const router = useRouter();

    const localCard = reactive({ ...props.card });

    watch(
      () => props.card,
      (newVal: Object) => {
        Object.assign(localCard, newVal);
      }
    );

    const handleCollectClick = (IdeaId: number) => {
      if (localCard.IsCollect) {
        ideaCollectCancel(IdeaId);
      } else {
        ideaCollect(IdeaId);
      }
      //localCard.IsCollect = !localCard.IsCollect;
      context.emit("update:card", localCard);
      context.emit("operationCountChange");
    };

    const handleLikeClick = (IdeaId: number) => {
      if (localCard.IsLike) {
        ideaLikeCancel(IdeaId);
      } else {
        ideaLike(IdeaId);
      }
      // localCard.IsLike = !localCard.IsLike;
      context.emit("update:card", localCard);
      context.emit("operationCountChange");
    };

    const handleCommentClick = () => {
      router.push("/ideas/123");
    };

    const ideaCollect = (IdeaId: number) => {
      axiosInstance
        .request({
          url: "api/Idea/IdeaCollect",
          data: {
            ideaId: IdeaId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log(res);
        });
    };

    const ideaCollectCancel = (IdeaId: number) => {
      axiosInstance
        .request({
          url: "api/Idea/IdeaCollectCancel",
          data: {
            ideaId: IdeaId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log(res);
        });
    };

    const ideaLike = (IdeaId: number) => {
      axiosInstance
        .request({
          url: "api/Idea/IdeaLike",
          data: {
            ideaId: IdeaId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log(res);
        });
    };

    const ideaLikeCancel = (IdeaId: number) => {
      axiosInstance
        .request({
          url: "api/Idea/IdeaLikeCancel",
          data: {
            ideaId: IdeaId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log(res);
        });
    };

    return {
      localCard,
      customGap: props.customGap,
      handleCollectClick,
      handleLikeClick,
      handleCommentClick,
    };
  },
});
</script>
