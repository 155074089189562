import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0e775e9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "full-screen" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_HeaderBar, { index: '/playground' })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("iframe", {
        ref: "iframe",
        src: $setup.iframeSrc,
        style: {"height":"100vh","width":"100vw"},
        frameborder: "0",
        marginwidth: "0",
        marginheight: "0",
        scrolling: "no",
        allowtransparency: "true"
      }, null, 8, _hoisted_4)
    ])
  ]))
}