<template>
  <!-- Header导航栏部分 -->
  <header class="header">
    <ManagementHeaderBar :index="'/stastics'" />
  </header>
  <div style="padding: 2rem; font-size: 2rem; margin-top: 4rem">
    {{ $t("ideaStatistics") }}
  </div>
  <el-select
    v-model="value"
    class="m-2"
    size="large"
    :placeholder="$t('allTime')"
    style="width: 240px; --el-color-primary: #f36633; padding-left: 2rem"
    @change="selectChange"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      style="--el-color-primary: #f36633"
    />
  </el-select>
  <div ref="chartDom" class="chartDom"></div>
</template>

<style scoped>
.chartDom {
  width: 600px;
  height: 400px;
  padding: 1rem;
}
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(5px);
}
</style>
<script lang="ts">
import ManagementHeaderBar from "@/components/ManagementHeaderBar.vue";
import * as echarts from "echarts";
import { ref, onMounted, inject, computed } from "vue";
import { useI18n } from "vue-i18n";
import { AxiosInstance } from "axios";
export default {
  name: "ideaStatisticsView",
  components: {
    ManagementHeaderBar,
  },
  setup() {
    const axiosInstance = inject("axiosInstance") as AxiosInstance;
    const chartDom = ref(null);
    let chartInstance = null;
    const { t } = useI18n();
    const value = ref("");
    interface DataItem {
      IdeaCount: number;
      YearMonth: string;
      Status1Count: number;
      Status2Count: number;
      Status3Count: number;
      Status4Count: number;
    }
    const options = computed(() => [
      {
        value: "999",
        label: t("allTime"),
      },
      {
        value: "12",
        label: t("withinayear"),
      },
      {
        value: "6",
        label: t("withinsixmonths"),
      },
    ]);
    const StartTime = ref();
    const EndTime = ref();
    onMounted(() => {
      let now = new Date();
      EndTime.value = now.toDateString();
      let tempTime = new Date(now.getTime());
      tempTime.setMonth(now.getMonth() - 999);
      StartTime.value = tempTime.toDateString();

      getChartData();
    });
    const getChartData = async () => {
      return axiosInstance
        .request({
          url: "/api/idea/ChartByMonth",
          method: "get",
          params: {
            StartTime: StartTime.value,
            EndTime: EndTime.value,
          },
        })
        .then((res) => {
          let data: DataItem[] = res.data.Data; // 使用 res.data.Data
          data.sort((a, b) => {
            return a.YearMonth.localeCompare(b.YearMonth);
          });
          let xAxisData: string[] = data.map(
            (item: DataItem) => item.YearMonth
          );
          let seriesData1: number[] = data.map(
            (item: DataItem) => item.Status1Count
          );
          let seriesData2: number[] = data.map(
            (item: DataItem) => item.Status2Count
          );
          let seriesData3: number[] = data.map(
            (item: DataItem) => item.Status3Count
          );
          let seriesData4: number[] = data.map(
            (item: DataItem) => item.Status4Count
          );
          const totalData = seriesData1.map((value, index) => {
            return (
              value +
              seriesData2[index] +
              seriesData3[index] +
              seriesData4[index]
            );
          });
          chartInstance = echarts.init(chartDom.value);
          chartInstance.setOption({
            tooltip: {
              trigger: "axis",
            },
            color: "#f36633",
            xAxis: {
              data: xAxisData,
            },
            yAxis: {},
            legend: {
              data: [t("new"), t("discussion"), t("building"), t("pilot")],
            },
            axisPointer: {
              type: "shadow",
            },
            series: [
              {
                name: t("sum"),
                type: "bar",
                barGap: "-100%",
                statck: "",
                z: 0,
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    textStyle: { color: "#000" },
                  },
                },
                itemStyle: {
                  color: "#00000000",
                },
                data: totalData,
              },
              {
                name: t("new"),
                type: "bar",
                stack: "total",
                data: seriesData1,
                itemStyle: {
                  color: "#f36633",
                },
              },
              {
                name: t("discussion"),
                type: "bar",
                stack: "total",
                data: seriesData2,
                itemStyle: {
                  color: "#00c27c",
                },
              },
              {
                name: t("building"),
                type: "bar",
                stack: "total",
                data: seriesData3,
                itemStyle: {
                  color: "#007cc2",
                },
              },
              {
                name: t("pilot"),
                type: "bar",
                stack: "total",
                data: seriesData4,
                itemStyle: {
                  color: "#8535eb",
                },
              },
            ],
          });
        });
    };
    const selectChange = (e: any) => {
      let now = new Date();
      EndTime.value = now.toDateString();
      let tempTime = new Date(now.getTime());
      tempTime.setMonth(now.getMonth() - e);
      StartTime.value = tempTime.toDateString();
      getChartData();
    };
    return {
      value,
      options,
      chartDom,
      selectChange,
    };
  },
};
</script>
