export default {
  loginBtn: "Sign in with Your Company Account",
  welcomeMainMsg: "Welcome to our Idea Box",
  welcomeSubMsg:
    "This is the place for your ideas. You can submit an idea, collaborate with others, get status updates and see what projects have already been completed.",
  submitIdea: "Submit Idea",
  andThen: "And Then",
  andThenMsg:
    "You can also take a look at other people's ideas and give them some advice.",
  new: "NEW",
  discussion: "DISCUSSION",
  building: "BUILDING",
  playground: "PLAYGROUND",
  pilot: "PILOT",
  pilotLower: "Pilot",
  workInProgress: "Work in Progress",
  updates: "Updates",
  viewMore: "view more",
  aboutGSK: "About GSK",
  aboutGSKMsg: "GSK plc. All Rights Reserved.",
  idea: "Idea",
  submitYourIdea: "Submit your Idea",
  title: "Title",
  imgCondition:
    "JPG, JPEG, PNG, or GIF - 5MB file limit <br> Recommended 320x240px or larger",
  chooseImg: "Choose Image",
  chooseImgAgain: "Choose Image Again",
  description: "Description",
  attachments: "Attachments",
  drag: "Drag and Drop - Browse your computer",
  cancel: "Cancel",
  home: "HOME",
  submit: "SUBMIT",
  browse: "BROWSE",
  allIdeas: "All Ideas",
  showMyIdeas: "Show My Ideas",
  searchAnything: "Search Anything...",
  operation: "Operation",
  status: "Status",
  department: "Department",
  owner: "Owner",
  clear: "Clear",
  collected: "Collected",
  supported: "Supported",
  commented: "Commented",
  comments: "Comments",
  submitted: "Submitted",
  followIdea: "Follow Idea",
  unfollowIdea: "Unfollow Idea",
  users: "Users",
  yourSuggestions: "Your Suggestions",
  yourSuggestionsMsg: "Tell me about your suggestions",
  viewProfile: "View Profile",
  job: "Job Title",
  ideas: "Ideas",
  ideasUpper: "IDEAS",
  noSubmissions: "No submissions submitted.",
  myIdeas: "My Ideas",
  myComments: "My Comments",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  oldPassword: "Old Password",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  passwordMsg: "Your new passwords are inconsistent.",
  userPool: "USER POOL",
  ideasManagement: "IDEAS MANAGEMENT",
  createTime: "Create Time",
  lastUpdate: "Last Update",
  detail: "Detail",
  action: "Action",
  searchIdeaName: "Search Idea Name...",
  commentsDetail: "Comments Detail",
  reply: "Reply",
  replyMsg: "Send your comments here...",
  onlyAdmin: "Only Admin",
  manageAuthority: "Manage Authority",
  submitLower: "Submit",
  edit: "Edit",
  delete: "Delete",
  confirm: "Confirm",
  applyMsg:
    "Once the administrator reviews your application, you will be notified via email.<br /> Are you sure you want to proceed with the administrator permission application?",
  adminUser: "ADMIN USER",
  playgroundLower: "Playground",
  followIdeaMsg: "Follow idea and send weekly progress report to mail.",
  userName: "User Name",
  lastLoginTime: "Last Login Time",
  creativeIdeas: "Creative Ideas",
  approve: "Approve",
  reject: "Reject",
  logout: "Log Out",
  noCommentData: "No Comment Data",
  justNow: "just now",
  minute: "minute",
  minutes: "minutes",
  hour: "hour",
  hours: "hours",
  day: "day",
  days: "days",
  week: "week",
  weeks: "weeks",
  month: "month",
  months: "months",
  year: "year",
  years: "years",
  ago: "ago",
  CreatedBy: "CreatedBy",
  Collects: "Collects",
  Likes: "Likes",
  reset: "Reset",
  ideaStatistics: "IDEAS STATISTICS",
  allTime: "All Time",
  withinayear: "Within a Year",
  withinsixmonths: "Within six months",
  sum: "SUM",
  exportExcel: "Export to Excel",
  warning: "warning",
  warningmsg: "This operation will result in permanent deletion. Continue?",
  deletesuccess: "Delete completed",
  deletecancel: "Delete cancelled",
  submit_warning: "Please DO NOT upload personal information and GSK sensitive data.",
};
