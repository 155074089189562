<template>
  <div class="header">
    <div class="left-container">
      <img
        src="@/assets/images/logo.png"
        style="cursor: pointer; height: 2.38rem; width: 11.1rem"
        @click="handleLogoClick"
      />
      <el-menu
        :default-active="index"
        mode="horizontal"
        :ellipsis="false"
        class="custom-menu"
        router="true"
      >
        <el-menu-item index="/">{{ $t("home") }}</el-menu-item>
        <el-menu-item index="/submit">{{ $t("submit") }}</el-menu-item>
        <el-menu-item index="/browse">{{ $t("browse") }}</el-menu-item>
      </el-menu>
    </div>
    <div class="right-container">
      <div class="dropdown-container">
        <el-dropdown @command="handleLanguageChange">
          <button class="dropdown-link">
            {{ dropdownValue }}<img src="@/assets/icons/caret-down.svg" />
          </button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(dropdownOption, dropdownOptionIndex) in dropdownOptions"
                :key="dropdownOptionIndex"
                :command="{
                  label: dropdownOption.label,
                  value: dropdownOption.value,
                }"
                >{{ dropdownOption.label }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <el-button
        class="gear-container"
        @click="handleGearClick"
        v-if="getUserInfo()!.graphUserInfo!.authority == 1"
        ><img src="@/assets/icons/gear.svg"
      /></el-button>
      <el-button
        class="gear-container"
        @click="applyAdmin = true"
        v-if="getUserInfo()!.graphUserInfo!.authority === 0 &&
          getUserInfo()!.graphUserInfo!.pendingAdmit === false
          "
        ><el-icon> <Avatar /> </el-icon
      ></el-button>
      <Teleport to="#app">
        <el-dialog
          v-model="applyAdmin"
          title="Tips"
          width="30%"
          style="z-index: 99999999"
        >
          <div v-html="$t('applyMsg')" class="apply-msg"></div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="applyAdmin = false">{{
                $t("cancel")
              }}</el-button>
              <el-button type="primary" @click="handleAdminRequestConfirm">
                {{ $t("confirm") }}
              </el-button>
            </span>
          </template>
        </el-dialog>
      </Teleport>
      <el-popover
        :width="160"
        popper-style="box-shadow: 0px 2px 10px 0px rgba(72, 72, 81, 0.10);padding:0"
        placement="bottom-end"
      >
        <template #reference>
          <el-avatar
            :src="'data:image/jpeg;base64,' + getUserInfo()!.graphUserInfo!.avatar"
            class="custom-avatar"
            style="margin-left: 1.5rem"
            :size="40"
          />
        </template>
        <template #default>
          <div class="avatar-pop-container">
            <div
              style="cursor: pointer; padding-top: 1rem; padding-bottom: 1rem"
              @click="handleProfileClick"
            >
              <el-avatar
                :src="'data:image/jpeg;base64,' + getUserInfo()!.graphUserInfo!.avatar"
                class="custom-avatar"
                :size="64"
              >
              </el-avatar>
              <div class="avatar-pop-name">
                {{
                  getUserInfo()?.graphUserInfo!.displayName
                    ? getUserInfo()?.graphUserInfo!.displayName
                    : getUserInfo()!.graphUserInfo!.userName
                }}
              </div>
            </div>

            <div class="logout-row">
              <el-button
                class="logout-btn"
                @click="handleLogoutClick"
                style="--el-color-primary: #f36633"
                ><img
                  src="@/assets/icons/log-out.svg"
                  style="margin-right: 0.75rem"
                />{{ $t("logout") }}</el-button
              >
            </div>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<style scoped>
.header {
  padding: 0px 2%;
  display: flex;
  min-height: 4rem;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  box-sizing: border-box;
}

.left-container {
  display: flex;
  align-items: center;
  width: auto;
}

.custom-menu {
  margin-left: 10%;
  background-color: transparent;
  margin-top: 5px;
}

:deep(.el-input__wrapper) {
  background-color: transparent;
  box-shadow: none;
}

:deep(.el-input__inner) {
  color: #484851;
  text-align: center;
  font-family: GSK Precision;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  /* 150% */
}

:deep(.el-select) {
  font-weight: 400;
  --el-select-input-focus-border-color: #376f7b;
}

.el-dropdown__item {
  color: #3c3c43 !important;
}

:deep .custom-menu .el-menu-item {
  color: #868693 !important;
  font-weight: 700;
  line-height: 2.5rem;
}

.custom-menu .el-menu-item:hover,
.custom-menu .el-menu-item:focus,
.custom-menu .el-menu-item:active {
  background-color: transparent !important;
  color: black !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: black !important;
  border-color: black !important;
}

.el-menu {
  --el-menu-border-color: transparent;
}

.custom-menu .el-menu-item.is-active {
  color: inherit;
}

.right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.el-avatar {
  border: 2px solid rgba(174, 174, 183, 0.2);
}

.dropdown-container {
  display: flex;
  align-items: center;
  color: #484851;
  text-align: center;
  font-size: 1rem;
}

.gear-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 6px;
  border: 1px solid #c9c9cf;
  background-color: transparent;
  margin-left: 1rem;
}

.gear-container:hover,
.gear-container:active {
  border: 1px solid #376f7b;
  background: rgba(55, 111, 123, 0.1);
  color: inherit !important;
}

.gear-container:focus {
  border: 1px solid #376f7b;
  background-color: transparent;
  color: inherit !important;
}

.gear {
  width: 1.25rem;
  height: 1.25rem;
}

.dropdown-link {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  text-align: center;
  background-color: transparent;
  border-radius: 6px;
  border-color: transparent;
  font-family: GSK Precision;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  height: 2rem;
}

.dropdown-link:hover,
.dropdown-link:active,
.dropdown-link:focus {
  border: 1px solid #376f7b;
  background: rgba(55, 111, 123, 0.1);
}

.avatar-pop-container {
  padding-bottom: 0.5rem;
  border-radius: 8px;
  background: #fff;
  text-align: center;
}

.avatar-pop-name {
  margin-top: 0.5rem;
  color: #3c3c43;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 137.5%;
}

.logout-row {
  border-top: 1px solid #c9c9cf;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.logout-btn {
  height: 1.875rem;
  width: 100%;
  background-color: white;
  border: none;
  color: #868693;
  text-align: center;
  font-family: GSK Precision;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 157.143%;
  --el-button-border-color: #c9c9cf !important;
  --el-button-hover-bg-color: white !important;
  --el-button-active-bg-color: white !important;
}

.custom-avatar {
  cursor: pointer;
}

.apply-msg {
  color: #3c3c43;
  font-family: GSK Precision;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}

@media only screen and (max-width: 600px) {
  .custom-menu {
    display: none;
  }

  .dropdown-container {
    display: none;
  }
  .gear-container {
    display: none;
  }
}
</style>

<script lang="ts">
import { defineComponent, ref, reactive, inject } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import Avatar from "@element-plus/icons-vue";
import { getUserInfo, saveUserInfo } from "@/state/UserInfo";
import { AxiosInstance } from "axios";

export default defineComponent({
  name: "HeaderBar",
  props: {
    index: { type: String, default: "/" },
  },

  setup() {
    const dropdownValue = ref("English");
    const { locale } = useI18n();
    const userInfo = getUserInfo();
    const axiosInstance = inject("axiosInstance") as AxiosInstance;
    const dropdownOptions = reactive([
      {
        value: "en",
        label: "English",
      },
      {
        value: "zh",
        label: "简体中文",
      },
      {
        value: "jp",
        label: "にほんご",
      },
    ]);
    let matchedOption = dropdownOptions.find(
      (option) => option.value === locale.value
    );
    if (matchedOption) {
      dropdownValue.value = matchedOption.label;
    }
    const applyAdmin = ref(false);
    const router = useRouter();
    const savedLocale = localStorage.getItem("inhubLocale");
    if (savedLocale) {
      locale.value = savedLocale;
      if (savedLocale == "zh") {
        dropdownValue.value = "简体中文";
      } else if (savedLocale == "jp") {
        dropdownValue.value = "にほんご";
      } else {
        dropdownValue.value = "English";
      }
    }
    const handleLanguageChange = (e: any) => {
      dropdownValue.value = e.label;
      locale.value = e.value;
      localStorage.setItem("inhubLocale", locale.value);
    };

    const handleLogoutClick = async () => {
      await saveUserInfo(null);
      router.push("/login");
    };

    const handleProfileClick = () => {
      router.push("/profile");
    };

    const handleGearClick = () => {
      router.push("/userpool");
    };

    const handleLogoClick = () => {
      router.push("/");
    };
    const handleAdminRequestConfirm = () => {
      RequestAdminAuthority().then(() => {
        applyAdmin.value = false;
        const UserInfo = getUserInfo();
        UserInfo!.graphUserInfo!.pendingAdmit = true;
        saveUserInfo(UserInfo);
      });
    };
    const RequestAdminAuthority = () => {
      return axiosInstance
        .request({
          url: "api/User/RequestAdminAuthority",
          data: {
            UserId: userInfo!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log(res);
        });
    };

    return {
      dropdownValue,
      dropdownOptions,
      applyAdmin,
      userInfo,
      handleLanguageChange,
      handleLogoutClick,
      handleProfileClick,
      handleGearClick,
      handleLogoClick,
      getUserInfo,
      handleAdminRequestConfirm,
      Avatar,
    };
  },
});
</script>
