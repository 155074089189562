/* eslint-disable */
// if (process.env.NODE_ENV == "production") {
//   console.log = console.warn = console.error = console.info = function () {};
// }

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/styles/element-ui-reset.scss";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { createI18n } from "vue-i18n";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import axios, { AxiosInstance } from "axios";
import enMessages from "@/i18n/en";
import zhMessages from "@/i18n/zh";
import jpMessages from "@/i18n/jp";
import { formatTimeAgo } from "@/utils/formatTimeAgo";
import { formatTimestamp } from "./utils/formatTimestamp";
import { getUserInfo, saveUserInfo } from "@/state/UserInfo";

// 导入样式
import "element-plus/dist/index.css";

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

const i18n = createI18n({
  legacy: false,
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages: { en: enMessages, zh: zhMessages, jp: jpMessages }, // set locale messages
});

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

if (getUserInfo() && getUserInfo()?.tokenResult) {
  axiosInstance.interceptors.request.use((config) => {
    const token = getUserInfo()!.tokenResult!.access_token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
}

axiosInstance.interceptors.response.use(
  function (response) {
    // Response
    const userInfo = getUserInfo();
    if (userInfo && userInfo.tokenResult) {
      const currentTime = new Date().getTime();
      const expires_time =
        (userInfo!.tokenResult!.expire_date - currentTime) / 3600;
      console.log(expires_time);
      if (expires_time <= 200) {
        RefreshJwtToken();
      }
    }
    return response;
  },
  function (error) {
    if (error.code == "ERR_NETWORK") {
      router.push("/login");
    }
    console.error(error);
  }
);

const RefreshJwtToken = () => {
  return axiosInstance
    .request({
      url: "api/Token/RefreshJwtToken",
      data: {
        token: getUserInfo()?.tokenResult!.access_token,
      },
      method: "post",
    })
    .then((res) => {
      const userInfo = getUserInfo();
      if (userInfo && userInfo.tokenResult) {
        userInfo.tokenResult = res.data.data;
        saveUserInfo(userInfo);
      }
      console.log("refresh", userInfo);
    });
};

app.config.globalProperties.$formatTimeAgo = formatTimeAgo;
app.config.globalProperties.$formatTimestamp = formatTimestamp;
app.provide("formatTimeAgo", formatTimeAgo);
app.provide("formatTimestamp", formatTimestamp);

app.provide("axiosInstance", axiosInstance);
app.use(ElementPlus);
app.use(router);
app.use(i18n);
app.component("QuillEditor", QuillEditor);
app.mount("#app");
