import { useI18n } from "vue-i18n";
export function formatTimeAgo(timestamp: string): string {
  const { t } = useI18n(); // 获取 i18n 实例
  // 将日期时间字符串转换为时间戳，并且添加8个小时以校正时差
  const date = new Date(timestamp);
  date.setHours(date.getHours() + 8);
  const timestampMs = date.getTime();

  const currentTime = new Date().getTime();
  const timeDifference = currentTime - timestampMs;

  // 定义时间单位的毫秒数
  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  // 计算时间差对应的单位
  if (timeDifference < minute) {
    return t("justNow");
  } else if (timeDifference < hour) {
    const minutes = Math.floor(timeDifference / minute);
    return `${minutes} ${t(minutes > 1 ? "minutes" : "minute")} ${t("ago")}`;
  } else if (timeDifference < day) {
    const hours = Math.floor(timeDifference / hour);
    return `${hours} ${t(hours > 1 ? "hours" : "hour")} ${t("ago")}`;
  } else if (timeDifference < week) {
    const days = Math.floor(timeDifference / day);
    return `${days} ${t(days > 1 ? "days" : "day")} ${t("ago")}`;
  } else if (timeDifference < month) {
    const weeks = Math.floor(timeDifference / week);
    return `${weeks} ${t(weeks > 1 ? "weeks" : "week")} ${t("ago")}`;
  } else if (timeDifference < year) {
    const months = Math.floor(timeDifference / month);
    return `${months} ${t(months > 1 ? "months" : "month")} ${t("ago")}`;
  } else {
    const years = Math.floor(timeDifference / year);
    return `${years} ${t(years > 1 ? "years" : "year")} ${t("ago")}`;
  }
}
