export default {
  loginBtn: "使用 GSK 公司账户登录",
  welcomeMainMsg: "欢迎来到我们的想法箱",
  welcomeSubMsg:
    "这里是您的创意发源地。您可以提交想法，与他人协作，获取状态更新，并查看哪些项目已经完成。",
  submitIdea: "提交想法",
  andThen: "然后",
  andThenMsg: "您还可以查看其他人的想法，并给予一些建议。",
  new: "新的",
  discussion: "讨论",
  building: "建设中",
  playground: "游乐场",
  pilot: "试点",
  pilotLower: "试点",
  workInProgress: "进行中的工作",
  updates: "更新",
  viewMore: "查看更多",
  aboutGSK: "关于GSK",
  aboutGSKMsg: "GSK plc. 版权所有.",
  idea: "想法",
  submitYourIdea: "提交您的想法",
  title: "标题",
  imgCondition:
    "JPG、JPEG、PNG 或 GIF - 文件限制 5MB <br> 推荐尺寸 320x240px 或更大",
  chooseImg: "选择图片",
  chooseImgAgain: "重新选择图片",
  description: "描述",
  attachments: "附件",
  drag: "拖放 - 浏览您的电脑",
  cancel: "取消",
  home: "主页",
  submit: "提交",
  browse: "浏览",
  allIdeas: "所有想法",
  showMyIdeas: "展示我的想法",
  searchAnything: "搜索任何内容...",
  operation: "操作",
  status: "状态",
  department: "部门",
  owner: "所有者",
  clear: "清除",
  collected: "已收集",
  supported: "已支持",
  commented: "已评论",
  comments: "评论",
  submitted: "已提交",
  followIdea: "关注想法",
  unfollowIdea: "取消关注想法",
  users: "用户",
  yourSuggestions: "您的建议",
  yourSuggestionsMsg: "告诉我您的建议",
  viewProfile: "查看资料",
  job: "工作",
  ideas: "想法",
  noSubmissions: "没有提交的内容.",
  myIdeas: "我的想法",
  myComments: "我的评论",
  firstName: "名字",
  lastName: "姓氏",
  email: "电子邮件",
  oldPassword: "旧密码",
  newPassword: "新密码",
  confirmPassword: "确认密码",
  passwordMsg: "您的新密码不一致.",
  userPool: "用户池",
  ideasManagement: "想法管理",
  createTime: "创建时间",
  lastUpdate: "最后更新",
  detail: "详情",
  action: "行动",
  searchIdeaName: "搜索想法名称...",
  commentsDetail: "评论详情",
  reply: "回复",
  replyMsg: "在这里发送您的评论...",
  onlyAdmin: "仅管理员",
  manageAuthority: "管理权限",
  submitLower: "提交",
  edit: "编辑",
  delete: "删除",
  confirm: "确认",
  applyMsg:
    "管理员申请审批后，将会通过邮件通知您，<br />确认发起管理员权限申请吗？",
  adminUser: "管理员用户",
  playgroundLower: "游乐场",
  followIdeaMsg: "关注想法并发送每周进展报告到邮箱.",
  userName: "用户名",
  lastLoginTime: "最后登录时间",
  creativeIdeas: "创意想法",
  approve: "批准",
  reject: "拒绝",
  logout: "注销",
  ideasUpper: "想法",
  noCommentData: "暂无评论数据",
  justNow: "刚刚",
  minute: "分钟",
  minutes: "分钟",
  hour: "小时",
  hours: "小时",
  day: "天",
  days: "天",
  week: "周",
  weeks: "周",
  month: "月",
  months: "月",
  year: "年",
  years: "年",
  ago: "前",
  CreatedBy: "所有者",
  Collects: "收藏",
  Likes: "点赞",
  reset: "重置",
  ideaStatistics: "想法统计",
  allTime: "全部时间",
  withinayear: "一年内",
  withinsixmonths: "六个月内",
  sum: "总计",
  exportExcel: "导出Excel",
  warning: "警告",
  warningmsg: "此操作将导致永久删除。继续吗？",
  deletesuccess: "删除成功",
  deletecancel: "取消删除",
  submit_warning: "请勿上传个人信息和GSK敏感数据。",
};
